<template>
    <transition name="fading">
        <div v-if="isVisible" class="overlay">
            <div class="overlay-content text-center">
              <div class="container">
                  <div class="card-body">
                    <div class="card card-shadow m-2 p-3">
                      <div class="my-2">{{ promptText }}</div> 
                      <button class="btn m-2 green-btn" @click="handleYes">{{ yesText }}</button>
                      <button class="btn m-2 red-btn" @click="handleNo">{{ noText }}</button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
    </transition>
  </template>
  
  <script setup>
  import { defineProps, defineEmits } from 'vue';
  
  // Define props
  const props = defineProps({
    isVisible: {
      type: Boolean,
      required: true,
    },
    promptText: {
      type: String,
      required: true,
    },
    yesText: {
      type: String,
      default: 'Yes',
    },
    noText: {
      type: String,
      default: 'No',
    },
  });
  
  // Emit events to parent component
  const emit = defineEmits(['yes', 'no']);
  
  // Handle Yes button click
  const handleYes = () => {
    emit('yes');
  };
  
  // Handle No button click
  const handleNo = () => {
    emit('no');
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
    .green-btn{
    background-color: var(--primary-color);
    color: white;
    padding:8px;
    }
    
    .red-btn{
    background-color: #dc362e !important;
    color: white;
    padding:8px;
    }

  .overlay-content {
    position: relative;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    }
    
    .overlay-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 2rem;
    }
    
    .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor:auto;
    }

.fading-enter-active,
.fading-leave-active {
transition: opacity 0.3s
}

.fading-enter-from,
.fading-leave-to {
opacity: 0
}
  </style>
  