<template>

    <div class="d-flex align-items-center w-100">
        <input
          type="text"
          class="form-control"
          v-model="searchQuery"
          :placeholder="searchPlaceholder"
          @keypress.enter.prevent="submit"
        />
        <button class="btn btn-green ms-2" style="width:120px;" @click="submit" @keypress.enter.prevent="submit">{{ buttonPlaceholder }}</button>
    </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue';

const props = defineProps({
    searchPlaceholder: {
      type: String,
    },
    buttonPlaceholder: {
        type: String,
    }
  });

const searchQuery = ref('');

const emit = defineEmits(['OutputSearch']);

const submit = () => {
  emit('OutputSearch', searchQuery.value);
};

</script>

<style scoped>
.btn-green{
    background-color: var(--primary-color);
    color: white;
}

.selected-categories {
  margin-top: 10px;
}

.category-tag {
  display: inline-block;
  background-color: #f9effa;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 5px;
}

.category-tag i {
  margin-left: 8px;
  cursor: pointer;
}

/* Adjustments for better spacing and layout */
.d-flex.w-50 {
  width: 50%;
}

.select.w-50 {
  width: 50%;
}

.ms-2 {
  margin-left: 0.5rem;
}
</style>