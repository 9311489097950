import axios from 'axios';
import { useAuthStore } from '../stores/authStore';

const BACKEND_URL = "https://drmatabackend-584749671098.asia-southeast1.run.app"

export const getDashboard = async (period) => {
    const authStore = useAuthStore();
    
    // Calculate dates
    const endDate = new Date();
    const startDate = new Date();
    
    if (period === 'week') {
        startDate.setDate(startDate.getDate() - 7);
    } else if (period === 'month') {
        startDate.setMonth(startDate.getMonth() - 1);
    }

    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/statistics`, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
            params: {
                start_date: startDate.toISOString().split('T')[0],
                end_date: endDate.toISOString().split('T')[0]
            }
        });
    
        return response.data.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            await authStore.refreshAccessToken();
            return getDashboard(period);
        }
        throw error;
    }
}

export const getDashboardbyDate = async (periodStart, periodEnd) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/statistics`, {
            headers: {
                Authorization: `Bearer ${authStore.accessToken}`,
            },
            params: {
                start_date: periodStart,
                end_date: periodEnd
            }
        });
    
        return response.data.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            await authStore.refreshAccessToken();
            return getDashboardbyDate(periodStart, periodEnd);
        }
        throw error;
    }
}



