import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://drmatabackend-584749671098.asia-southeast1.run.app';

export const getAllPatient = async () => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/patients`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllPatient(); // Retry after refreshing
        }
        throw error;
    }
}


// {
//     "PatientIdentifier": "string",
//     "PatientName": "string",
//     "PatientDOB": "string",
//     "PatientAgeAtScreening": 0,
//     "OtherPatientIds": "string",
//     "PersonUid": "string",
//     "PhysicianUid": "string",
//     "EthnicGroup": "string",
//     "Status": "pending",
//     "IsSmoker": false,
//     "PhoneNumber": "string",
//     "ICNumber": "string",
//     "PassportNumber": "string",
//     "TypeOfDiabetes": "string",
//     "OriginHospitalUUid": "string"
//   }

export const registerPatient = async (patientData) => {
    const authStore = useAuthStore();

    console.log(patientData);

    try {
      const response = await axios.post(`${BACKEND_URL}/drmata_user/patients`, patientData, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerPatient(patientData); // Retry after refreshing
        }
        throw error;
    }
};

export const getCurrentPatient = async (patientUUID) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/patient/${patientUUID}`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getCurrentPatient (patientUUID); // Retry after refreshing
        }
        throw error;
    }
};

export const EditCurrentPatient = async (patientUUID, patientData) => {
    const authStore = useAuthStore();

    try {
        const response = await axios.put(`${BACKEND_URL}/drmata_user/patient/${patientUUID}`, patientData,{
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return EditCurrentPatient(patientUUID); // Retry after refreshing
        }
        throw error;
    }
}