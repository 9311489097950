<template>
  <Transition name="slide">
    <div v-if="isOpen" class="pdf-container" :class="{ 'mobile': isMobile }">
      <div class="fixed-header">
        <button @click="close" class="close-pdf mx-2">×</button>
        <div class="pdf-toolbar">
          <!-- <button @click="downloadPdf" >
            Download <i class="fas fa-download"></i>
          </button>
          <button @click="openInNewTab">
            Open in Browser <i class="fa fa-external-link"></i>
          </button> -->
        </div>
      </div>
      <div class="pdf-wrapper">
        <LoadingSmall v-if="loading" title="Loading PDF..." />
        <VuePdfEmbed
          :source="source"
          :page="currentPage"
          :style="pdfStyle"
          @rendered="onPdfRendered"
          @error="loading = false"
          @pageCount="totalPages = $event"
        />
      </div>
    </div>
  </Transition>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'
import { useWindowSize } from '@vueuse/core'
import LoadingSmall from '@/components/LoadingSmall.vue'

export default {
  name: 'PdfViewer',
  components: { VuePdfEmbed, LoadingSmall },

  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      loading: true,
      currentPage: this.page,
      totalPages: 1
    }
  },

  watch: {
    page(newPage) {
      this.currentPage = newPage
    },
    currentPage(newPage) {
      this.$emit('update:page', newPage)
    }
  },

  setup() {
    const { width } = useWindowSize()
    return { width }
  },

  computed: {
    isMobile() {
      return this.width < 768
    },
    pdfStyle() {
      return {
        height: this.isMobile ? 'calc(90vh - 2rem)' : 'calc(100vh - 2rem)',
        width: '100%'
      }
    }
  },

  methods: {
    close() {
      this.$emit('update:isOpen', false)
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    onPdfRendered() {
      this.loading = false
    },
    downloadPdf() {
      const link = document.createElement('a')
      link.href = this.source
      link.download = 'cpg-document.pdf'
      link.click()
    },
    openInNewTab() {
      window.open(this.source, '_blank')
    }
  }
}
</script>


<style scoped>
.pdf-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100vh;
  background: white;
  box-shadow: -2px 0 10px rgba(0,0,0,0.1);
  z-index: 1000;
  padding: 1rem;
  overflow-y: auto;
}

.pdf-container.mobile {
  width: 100%;
  left: 0;
}

.pdf-wrapper {
  height: 100%;
  overflow-y: auto;
}

.close-pdf {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

@media (max-width: 768px) {
  .slide-enter-from,
  .slide-leave-to {
    transform: translateY(100%);
  }
}
</style>

<!-- TOOLBAR -->
<style scoped>
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1002;
  background: white;
  padding: 1rem;
  margin: -1rem -1rem 0 -1rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.close-pdf {
  font-size: 1.25rem;
  background: #f8f8f8;
  border: 1px solid #ddd;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  margin-top: 0.75rem;
  color: black;
}

.pdf-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.75rem;
  background: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  flex: 1;
}

@media (max-width: 768px) {
  .fixed-header {
    padding: 0.75rem;
    gap: 0.75rem;
  }

  .close-pdf {
    margin-top: 0.5rem;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }
}
</style>

<!-- DOWNLOAD BUTTON -->
<style scoped>
.download-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.download-btn:hover {
  background: var(--primary-hover);
}

.download-btn i {
  font-size: 0.9rem;
}
</style>