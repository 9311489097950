<template>
    <div class="d-flex vh-100">
      <!-- Sidebar -->
      <nav :class="[
        'bg-color border-end',
        'transition-all duration-300',
        isOpen || !isMobile ? 'width-240' : 'width-60',
        isMobile ? 'position-fixed h-100' : '',
        isMobile ? (isOpen ? 'start-0' : 'start-negative') : 'start-0'
      ]" style="z-index: 1030;">
        <div class="d-flex flex-column h-100">
          
          <!-- Logo -->
          <div class="p-3 d-flex justify-content-between align-items-center">
            <router-link class="navbar-brand d-flex flex-column align-items-center" to="/">
              <img src="../assets/DrMata/LOGO-DRMATA-White.png" alt="Logo" width="140" class="mx-auto">
              <!-- <span class="navbar-text fw-bold " style="font-size: 12px; margin-top: -10px;">Dr MATA Clinic</span> -->
            </router-link>
            <button v-if="isMobile" @click="toggleSidebar" class="btn btn-light d-lg-none">
              <XIcon :width="24" :height="24" />
            </button>
          </div>
  
          <!-- Navigation Items -->
          <ul class="nav flex-column flex-grow-1 mb-auto">

            <li class="nav-item">
              <div class="nav-link d-flex align-items-center px-3 py-2">
                <b>{{orgname ? orgname : 'Dr Mata Clinic'}}</b>
              </div>
            </li>

            <li v-for="item in Clinical" :key="item.name" class="nav-item">
              <router-link
                :to="item.path"
                class="nav-link d-flex align-items-center px-3 py-2"
                :class="{ 'active': isActive(item.path) }"
                @click="isMobile && toggleSidebar()"
              >
                <component :is="item.icon" class="me-3" :width="20" :height="20" />
                <span :class="{ 'd-none': !isOpen && !isMobile }">{{ item.name }}</span>
              </router-link>
            </li>

            
          </ul>

          <!-- Footer -->
          <div class="border-top p-3">
            <!-- User Profile -->
            <ul class="nav flex-column flex-grow-1 mb-auto">
              <div class="d-flex align-items-center px-3">
                <span :class="{ 'd-none': !isOpen && !isMobile }"><CircleUserRoundIcon/> {{ username }}</span>
              </div>
            </ul>

            <ul class="nav flex-column flex-grow-1 mb-auto mt-1">
                <div class="nav-link d-flex align-items-center px-3 py-2 log-out">
                    <span @click="LogOut"><LogOutIcon /> Log Out</span>
                </div>
            </ul>

          </div>
        </div>
      </nav>
  
      <!-- Main Content -->
      <div class="flex-grow-1 overflow-auto position-relative">
        <!-- Mobile Toggle Button -->
        <button v-if="isMobile && !isOpen" @click="toggleSidebar"
          class="btn btn-light position-fixed top-0 start-0 mt-2 ms-2"
          style="z-index: 1040;"
        >
          <MenuIcon :width="24" :height="24" />
        </button>
  
        <!-- Page Content -->
        <div class="w-100">
          <slot></slot>
        </div>
      </div>
  
      <!-- Overlay for mobile -->
      <div v-if="isMobile && isOpen" class="position-fixed top-0 start-0 w-100 h-100 bg-dark opacity-50" style="z-index: 1020;" @click="toggleSidebar"></div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { 
    MenuIcon, 
    XIcon, 
    HomeIcon, 
    UserIcon, 
    PillIcon, 
    HospitalIcon , 
    LogOutIcon, 
    BandageIcon, 
    CircleUserRoundIcon, 
    ScanEyeIcon, 
    LayoutDashboardIcon, 
    BrainCircuitIcon,
    FileTextIcon
   } from 'lucide-vue-next'
  import { useAuthStore } from '../stores/authStore';
  
  const route = useRoute();
  const router = useRouter();
  const isOpen = ref(false);
  const isMobile = ref(false);
  const authStore = useAuthStore();
  const username = ref(null);
  const orgname = ref(null);
  const role = ref(null);
  
  const Clinical = [
    { name: 'Dashboard', path: '/', icon: LayoutDashboardIcon },
    { name: 'Retina', path: '/retina/upload', icon: ScanEyeIcon },
    { name: 'Patients', path: '/patients', icon: BandageIcon },
    { name: 'Referral', path: '/referral', icon: FileTextIcon },
    { name: 'Ask CPG', path: '/cpg-chat', icon: BrainCircuitIcon },
  //  { name: 'Dr Mata Test', path: '/test', icon: HospitalIcon },
  ]
  
  const toggleSidebar = () => {
    isOpen.value = !isOpen.value
  }
  
  const isActive = (path) => {
    return route.path === path
  }
  
  const checkMobile = () => {
    isMobile.value = window.innerWidth < 992 // Bootstrap's lg breakpoint
    if (!isMobile.value) {
      isOpen.value = true
    } else {
      isOpen.value = false
    }
  }

  const LogOut = async () => {
    try {
        authStore.logout();
        router.push("/login");
      } 
      catch (error) {
        console.error('Error logging out:', error);
      }
  }

  const fetchUser = async () => {
      if(authStore.accessToken) {
        try{
          const userDetails = await authStore.fetchUserDetails();
          console.log(userDetails);
          if(userDetails.Username){
            username.value = userDetails.Username; 
          }
          if(userDetails.OrganizationName){
            orgname.value = userDetails.OrganizationName    
          }
          if(userDetails.Role){
            role.value = userDetails.Role
          }
        }
        catch{
          console.log("Error fetching user details");
          router.push("/login");
        }
      }
  }
  
  onMounted(() => {
    checkMobile()
    fetchUser()
    window.addEventListener('resize', checkMobile)
  })
  
  onUnmounted(() => {
    window.removeEventListener('resize', checkMobile)
  })
  </script>
  
  <style scoped>
  .width-240 {
    width: 240px;
  }
  
  .width-60 {
    width: 60px;
  }

  div{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    }
  
  .transition-all {
    transition: all 0.3s ease-in-out;
  }
  
  .start-negative {
    left: -240px;
  }

  .nav-link{
    color: black;
  }

  .active{
    color: var(--navbar-dark);
    background-color: var(--navbar-light);
    border-radius: 6px;
  }

  .log-out{
    cursor:pointer;
    color: red;
  }

  .bg-color{
    background-color: #f9fafe;
  }
  
  @media (max-width: 991.98px) {
    .start-negative {
      left: -240px;
    }
  }
  
  @media (min-width: 992px) {
    .start-0 {
      left: 0 !important;
    }
  }
  </style>