<template>
    <transition-group name="fading">
      <div class="container py-4">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="card shadow-sm round-card">
              <div class="card-header bg-primary text-white py-3">
                <h4 class="mb-0 text-center">Patient Edit Form</h4>
              </div>
  
              <div class="card-body">
  
                <div class="alert alert-danger text-center" v-if="isEmpty">
                  <i class="fas fa-exclamation-circle"></i> Please fill in all required fields
  
                  <div v-if="missingFields.length > 0">
                    <a><span v-for="fields in missingFields">{{ fields }}, </span></a>
                  </div>
                </div>
  
                <div class="alert alert-success text-center" v-if="isSuccess">
                  <i class="fas fa-check-circle"></i> Patient registration completed successfully
                </div>
  
                <!-- Group personal information -->
                <div class="mb-4">
                  <h5 class="border-bottom pb-2 text-primary">Personal Information</h5>
                  <div class="row g-3">
                    <div class="col-md-6">
                      <label class="form-label">Patient ID*</label>
                      <input class="form-control" v-model="patient_data.PatientIdentifier" placeholder="Enter Patient ID">
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Full Name*</label>
                      <input class="form-control" v-model="patient_data.PatientName" placeholder="Enter Full Name">
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Date of Birth*</label>
                      <input type="date" class="form-control" v-model="patient_data.PatientDOB">
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Age at Screening*</label>
                      <input type="number" class="form-control" v-model="patient_data.PatientAgeAtScreening">
                    </div>
                  </div>
                </div>
  
                <!-- Group identification -->
                <div class="mb-4">
                  <h5 class="border-bottom pb-2 text-primary">Identification Details</h5>
                  <div class="row g-3">
                    <div class="col-md-12">
                      <label class="form-label">Identification Type</label>
                      <select class="form-select" v-model="selectedIdType">
                        <option value="">Select Identification Type</option>
                        <option value="ic">IC Number</option>
                        <option value="passport">Passport Number</option>
                      </select>
                    </div>
                    
                    <div class="col-md-12" v-if="selectedIdType === 'ic'">
                      <label class="form-label">IC Number Without Dashes</label>
                      <input class="form-control" v-model="patient_data.ICNumber" placeholder="Enter IC Number Without Dashes">
                    </div>
                    
                    <div class="col-md-12" v-if="selectedIdType === 'passport'">
                      <label class="form-label">Passport Number</label>
                      <input class="form-control" v-model="patient_data.PassportNumber" placeholder="Enter Passport Number">
                    </div>
                  </div>
                </div>
  
                <!-- Group medical information -->
                <div class="mb-4">
                  <h5 class="border-bottom pb-2 text-primary">Medical Information</h5>
                  <div class="row g-3">
                    <div class="col-md-6">
                      <label class="form-label">Type of Diabetes*</label>
                      <select class="form-select" v-model="patient_data.TypeOfDiabetes">
                        <option value="">Select Type</option>
                        <option value="none">None</option>
                        <option value="type1">Type 1</option>
                        <option value="type2">Type 2</option>
                        <option value="gestational">Gestational</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Status*</label>
                      <select class="form-select" v-model="patient_data.Status">
                        <option value="pending">Pending</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                    <div class="col-12">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="patient_data.IsSmoker" id="smokerCheck">
                        <label class="form-check-label" for="smokerCheck">Patient is a smoker</label>
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- Group contact information -->
                <div class="mb-4">
                  <h5 class="border-bottom pb-2 text-primary">Contact Information</h5>
                  <div class="row g-3">
                    <div class="col-md-6">
                      <label class="form-label">Phone Number*</label>
                      <input class="form-control" v-model="patient_data.PhoneNumber" placeholder="Enter Phone Number" @input="allowOnlyDigits">
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Origin Hospital</label>
                      <input class="form-control" v-model="patient_data.OriginHospitalUUid" placeholder="Enter Hospital">
                    </div>
                  </div>
                </div>
  
                <div class="d-flex justify-content-end gap-2 mt-4">
                  <button class="btn btn-secondary px-4" @click="$router.go(-1)">
                    <i class="fa fa-times me-1"></i> Cancel
                  </button>
                  <button class="btn btn-primary px-4" @click="submit()">
                    <i class="fa-solid fa-save me-1"></i> Save Patient
                  </button>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </template>
  
  
  <script>
  import { getCurrentPatient , EditCurrentPatient } from "@/services/patientServices"
  import Loading from "@/components/Loading.vue";
  export default{
  
  components: {
      Loading,
  },
  
  watch: {
  },
  
  mounted() {
    this.get_current_patient()
  },
  
  data() {return {   
      loaded: true,
      patient_data: {
          PatientIdentifier: "",
          PatientName: "",
          PatientDOB: "",
          PatientAgeAtScreening: 0,
          OtherPatientIds: "",
          PersonUid: "",
          PhysicianUid: "",
          EthnicGroup: "",
          Status: "pending",
          IsSmoker: false,
          PhoneNumber: "",
          ICNumber: "",
          PassportNumber: "",
          TypeOfDiabetes: "",
          OriginHospitalUUid: ""
        },
      selectedIdType: "",
      isEmpty : false,
      missingFields: [],
      isSuccess: false,
      selectedClinic: "",
      clinicsJson: [],
      clinicsSelection: [],
      showPassword: false,
      OrganizationList: [],
      current_patient_uuid: '',
      }
  },
  methods: {
  
    checkEmpty() {
      //       PatientIdentifier: "",
      //       PatientName: "",
      //       PatientDOB: "",
      //       PatientAgeAtScreening: 0,
      //       OtherPatientIds: "",
      //       PersonUid: "",
      //       PhysicianUid: "",
      //       EthnicGroup: "",
      //       Status: "pending",
      //       IsSmoker: false,
      //       PhoneNumber: "",
      //       ICNumber: "",
      //       PassportNumber: "",
      //       TypeOfDiabetes: "",
      //       OriginHospitalUUid: ""
  
      const requiredFields = {
        PatientIdentifier: 'Patient ID',
        PatientName: 'Full Name',
        PatientDOB: 'Date of Birth',
        PatientAgeAtScreening: 'Age at Screening',
      };
      
      this.missingFields = [];
      
      for (const [key, label] of Object.entries(requiredFields)) {
        if (!this.patient_data[key]) {
          this.missingFields.push(label);
        }
      }
  
      console.log(this.missingFields);
  
      return this.missingFields.length > 0;
    },

    async get_current_patient(){
        this.current_patient_uuid = this.$route.params.uuid;
        const response = await getCurrentPatient(this.current_patient_uuid);
        console.log(response.data);
        const data = response.data;

        this.patient_data = {
          PatientIdentifier: data.PatientIdentifier,
          PatientName: data.PatientName,
          PatientDOB: data.PatientDOB ? data.PatientDOB.split(' ')[0] : data.PatientDOB,          
          PatientAgeAtScreening: data.PatientAgeAtScreening,
          OtherPatientIds: data.OtherPatientIds,
          PersonUid: data.PersonUid,
          PhysicianUid: data.PhysicianUid,
          EthnicGroup: data.EthnicGroup,
          Status: data.Status,
          IsSmoker: data.IsSmoker,
          PhoneNumber: data.PhoneNumber,
          ICNumber: data.ICNumber,
          PassportNumber: data.PassportNumber,
          TypeOfDiabetes: data.TypeOfDiabetes,
          OriginHospitalUUid: data.OriginHospitalUUid
        }
    },
  
    async submit(){
      this.isEmpty = this.checkEmpty(); 
      this.isSuccess = false;
  
        if(!this.isEmpty ){
            this.loaded = false;
  
            console.log('Submitting data:', this.patient_data);
  
            try{
              const response = await EditCurrentPatient(this.current_patient_uuid, this.patient_data);
  
              if(response){
                  this.loaded = true;
                  this.isSuccess = true;
              }
  
              setTimeout(() => {
                this.isSuccess = false;
              }, 3000);
  
            }
            catch(error){
                console.log(error);
                this.loaded = true;
            }
          
          this.$emit('modal-submit');
        }
    },
  
    noSpaces(event) {
        this.patient_data.username = event.target.value.replace(/\s+/g, '');
    },
  
    allowOnlyDigits(event) {
        this.patient_data.PhoneNumber = event.target.value.replace(/\D/g, '');  // Remove all non-digit characters
    },
  
  
  
  }
  }
  
  
  </script>
  
  <style scoped>
  .form-label {
    font-weight: 500;
    margin-bottom: 0.3rem;
  }
  
  .card-header {
    background-color: var(--primary-color) !important;
  }
  
  .text-primary {
    color: var(--primary-color) !important;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  .btn-primary:hover {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
  }
  
  .form-control:focus, .form-select:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 0.25rem rgba(15, 186, 178, 0.25);
  }
  </style>