import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://drmatabackend-584749671098.asia-southeast1.run.app';

export const registerSession = async (sessionData) => {
    const authStore = useAuthStore();

    try {
      const response = await axios.post(`${BACKEND_URL}/drmata_user/sessions`, sessionData, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerSession(sessionData); // Retry after refreshing
        }
        throw error;
    }
}

export const getSessionImage = async (sessionUUID) => {
  const authStore = useAuthStore();

  try {
      const response = await axios.get(`${BACKEND_URL}/drmata_user/cases-by-session?SessionUuid=${sessionUUID}`, {
      headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
      },
      });
  
      return response.data;

  } catch (error) {
      if (error.response && error.response.status === 401) {
          // Token might be expired, attempt to refresh
          await authStore.refreshAccessToken();
          return getSessionImage(sessionUUID); // Retry after refreshing
      }
      throw error;
  }
}

export const getPatientSessionV1 = async (pattientUUID) => {
    const authStore = useAuthStore();
  
    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/sessions/${pattientUUID}`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
  
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getPatientSession(pattientUUID); // Retry after refreshing
        }
        throw error;
    }
}

export const getPatientSessionV2 = async (patientUUID) => {
  const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/drmata_user/cases-by-patient?PatientUuid=${patientUUID}&imageURL=false`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });

        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getPatientSessionV2(patientUUID); // Retry after refreshing
        }
        throw error;
    }
}

export const getSessionImageByPatient = async (patientUUID) => {
    const authStore = useAuthStore();
  
      try {
          const response = await axios.get(`${BACKEND_URL}/drmata_user/cases-by-patient?PatientUuid=${patientUUID}&imageURL=true`, {
          headers: {
              Authorization: `Bearer ${authStore.accessToken}`,
          },
          });
  
          return response.data;
  
      } catch (error) {
          if (error.response && error.response.status === 401) {
              // Token might be expired, attempt to refresh
              await authStore.refreshAccessToken();
              return getSessionImageByPatient(patientUUID); // Retry after refreshing
          }
          throw error;
      }
}