<template>
    <div class="d-flex justify-content-center align-items-center p-4">
        <div class="spinner-border text-primary" role="status">
        </div>
        <div class="mx-2">{{ title }}</div>
      </div>
</template>

<script>
export default {
  name: 'LoadingSmall',
  props: {
    title: {
      type: String,
      required: true
    }
  },
}
</script>