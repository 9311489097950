<script>
import { getCurrentPatient } from '@/services/patientServices';
import { registerSession } from '@/services/sessionServices';
import { uploadCaseGrading } from '@/services/uploadServices';
import TitleHeader from '@/components/TitleHeader.vue'

export default {
  components: {
      TitleHeader
    },
  data() {
    return {
      selectedPatient: '',
      input_remarks: '',
      patients: [], // This should be populated from your API
      leftEye1Preview: null,
      leftEye2Preview: null,
      rightEye1Preview: null,
      rightEye2Preview: null,

      leftEye1File: null,
      leftEye2File: null,
      rightEye1File: null,
      rightEye2File: null,

      isUploading: false,
      uploadProgress: 0,
      totalFiles: 0,
      uploadedFiles: 0,
      hold_session_uuid: '',

      showSuccessAlert: false,
      showErrorAlert: false,
      errorMessage: '',

      searchQuery: '',
      showResults: false,
      filteredPatients: [],

      patientUuid: '',
    }
  },
  mounted() {
    this.getAllPatient();
  },
  beforeUnmount() {
  },
  methods: {
    async getAllPatient(){
        this.patientUuid = this.$route.params.patientUuid
        if(this.patientUuid){
          const response = await getCurrentPatient(this.patientUuid);
          this.patients = response.data;
        }

        this.selectedPatient = this.patientUuid;
    },
    triggerFileInput(ref) {
      this.$refs[ref].click()
    },
    removeImage(type) {
      switch(type) {
        case 'leftEye1':
          this.leftEye1Preview = null
          break
        case 'leftEye2':
          this.leftEye2Preview = null
          break
        case 'rightEye1':
          this.rightEye1Preview = null
          break
        case 'rightEye2':
          this.rightEye2Preview = null
          break
      }
      // Clear the file input
      this.$refs[type].value = ''
    },

    handleLeftEye1Upload(event) {
      const file = event.target.files[0]
      if (file) {
        this.leftEye1File = file
        this.leftEye1Preview = URL.createObjectURL(file)
      }
    },

    handleLeftEye2Upload(event) {
      const file = event.target.files[0]
      if (file) {
        this.leftEye2File = file
        this.leftEye2Preview = URL.createObjectURL(file)
      }
    },

    handleRightEye1Upload(event) {
      const file = event.target.files[0]
      if (file) {
        this.rightEye1File = file
        this.rightEye1Preview = URL.createObjectURL(file)
      }
    },

    handleRightEye2Upload(event) {
      const file = event.target.files[0]
      if (file) {
        this.rightEye2File = file
        this.rightEye2Preview = URL.createObjectURL(file)
      }
    },

    async createSession(SessionDate,SessionNotes,patient_uuid){
        let payload_api = {
            SessionDate,
            SessionNotes,
            patient_uuid
        }

        console.log(payload_api);
      
        let session = null;
        try{
          session = await registerSession(payload_api);
          console.log("Session!",session.session);
        }
        catch(error){
          console.warn(error);
        }

        return session;
    },

    async uploadRetinaImage(imageFile, patientUuid, sessionUuid, eyeType) {
      try {
        const response = await uploadCaseGrading(imageFile, patientUuid, sessionUuid, eyeType)
        this.uploadedFiles++
        this.uploadProgress = Math.round((this.uploadedFiles / this.totalFiles) * 100)
        return response
      } catch (error) {
        throw error
      }
    },

    async handleUpload() {
      console.log(this.selectedPatient, this.input_remarks, this.leftEye1File, this.leftEye2File, this.rightEye1File, this.rightEye2File);

      const files = [
        this.leftEye1File,
        this.leftEye2File,
        this.rightEye1File,
        this.rightEye2File
      ].filter(file => file !== null)

      if (files.length === 0) {
        alert('Please upload at least one image')
        return
      }

      this.isUploading = true
      this.uploadProgress = 0
      this.uploadedFiles = 0
      this.totalFiles = files.length

      try {
        const currentDate = new Date().toISOString()
        const sessionResponse = await this.createSession(
          currentDate, 
          this.input_remarks, 
          this.selectedPatient
        )

        console.log('Session Response:', sessionResponse)

        if (!sessionResponse?.session?.SessionUuid) {
          throw new Error('Session UUID not found')
        }

        const sessionUuid = sessionResponse.session.SessionUuid
        this.hold_session_uuid = sessionUuid
        const patientUuid = this.selectedPatient

        const uploadPromises = []

        if (this.leftEye1File) {
          uploadPromises.push(
            this.uploadRetinaImage(this.leftEye1File, patientUuid, sessionUuid, 0)
          )
        }
        if (this.leftEye2File) {
          uploadPromises.push(
            this.uploadRetinaImage(this.leftEye2File, patientUuid, sessionUuid, 0)
          )
        }
        if (this.rightEye1File) {
          uploadPromises.push(
            this.uploadRetinaImage(this.rightEye1File, patientUuid, sessionUuid, 1)
          )
        }
        if (this.rightEye2File) {
          uploadPromises.push(
            this.uploadRetinaImage(this.rightEye2File, patientUuid, sessionUuid, 1)
          )
        }

        await Promise.all(uploadPromises)
        
        // Optional: Reset form or redirect

        this.showSuccessAlert = true
        setTimeout(() => {
          this.showSuccessAlert = false
          this.goto_images()
          this.resetForm()
        
        }, 3000)
        
      } catch (error) {
        this.errorMessage = `Upload failed: ${error.message}`
        this.showErrorAlert = true
        setTimeout(() => {this.showErrorAlert = false}, 3000)

      } finally {
        this.isUploading = false
      }
    },

    resetForm() {
      this.selectedPatient = ''
      this.input_remarks = ''
      this.leftEye1Preview = null
      this.leftEye2Preview = null
      this.rightEye1Preview = null
      this.rightEye2Preview = null
      this.leftEye1File = null
      this.leftEye2File = null
      this.rightEye1File = null
      this.rightEye2File = null
      this.uploadProgress = 0
      this.uploadedFiles = 0
      this.totalFiles = 0
    },

    goto_images(){
      if(this.hold_session_uuid){
        this.$router.push({ name: 'CaseImage', params: { patientUuid: this.selectedPatient, uuid: this.hold_session_uuid} });
      } 
    },


  }
}
</script>

<template>
    
  <TitleHeader :title="`Upload Retina`"/>

  <div class="card p-3 upload-container container">

      <div class="form-section">
        
        <div class="patient-card">
          <div class="patient-header">
            <i class="fas fa-user-circle"></i>
            <h3>Patient Information</h3>
          </div>
          
          <div class="patient-details-grid">
            <div class="detail-item">
              <span class="detail-label">Name</span>
              <span class="detail-value">{{ patients.PatientName }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Age</span>
              <span class="detail-value">{{ patients.PatientAgeAtScreening }} years</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Date of Birth</span>
              <span class="detail-value">{{ new Date(patients.PatientDOB).toLocaleDateString() }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-label">Diabetes Type</span>
              <span class="detail-value">{{ patients.TypeOfDiabetes || 'None' }}</span>
            </div>
          </div>
        </div>

        <div class="form-group">
            <label>Remarks</label>
            <textarea v-model="input_remarks" class="form-control" rows="4" placeholder="Write your remarks here.."></textarea>
        </div>
      </div>

      <div class="alert alert-success" v-if="showSuccessAlert">
        <i class="fas fa-check-circle"></i> All images uploaded successfully!
      </div>
      
      <div class="alert alert-danger" v-if="showErrorAlert">
        <i class="fas fa-exclamation-circle"></i> {{ errorMessage }}
      </div>

      <div class="images-section" v-if="!isUploading">
          <!-- Left Eyes Section -->
          <div class="left-eyes">
          <h3>Left Eyes</h3>
          <div class="image-container">
              <div class="image-upload-box">
              <div class="upload-area" @click="triggerFileInput('leftEye1')">
                  <template v-if="!leftEye1Preview">
                  <i class="fas fa-camera camera-icon"></i>
                  <p>Click to Upload</p>
                  </template>
                  <img v-else :src="leftEye1Preview" alt="Left Eye 1">
              </div>
              <input type="file" ref="leftEye1" @change="handleLeftEye1Upload" accept="image/*" class="hidden-input">
              <button v-if="leftEye1Preview" @click="removeImage('leftEye1')" class="remove-btn">Remove</button>
              </div>
      
              <div class="image-upload-box">
              <div class="upload-area" @click="triggerFileInput('leftEye2')">
                  <template v-if="!leftEye2Preview">
                  <i class="fas fa-camera camera-icon"></i>
                  <p>Click to Upload</p>
                  </template>
                  <img v-else :src="leftEye2Preview" alt="Left Eye 2">
              </div>
              <input type="file" ref="leftEye2" @change="handleLeftEye2Upload" accept="image/*" class="hidden-input">
              <button v-if="leftEye2Preview" @click="removeImage('leftEye2')" class="remove-btn">Remove</button>
              </div>
          </div>
          </div>
      
          <!-- Right Eyes Section -->
          <div class="right-eyes">
          <h3>Right Eyes</h3>
          <div class="image-container">
              <div class="image-upload-box">
              <div class="upload-area" @click="triggerFileInput('rightEye1')">
                  <template v-if="!rightEye1Preview">
                  <i class="fas fa-camera camera-icon"></i>
                  <p>Click to Upload</p>
                  </template>
                  <img v-else :src="rightEye1Preview" alt="Right Eye 1">
              </div>
              <input type="file" ref="rightEye1" @change="handleRightEye1Upload" accept="image/*" class="hidden-input">
              <button v-if="rightEye1Preview" @click="removeImage('rightEye1')" class="remove-btn">Remove</button>
              </div>
      
              <div class="image-upload-box">
              <div class="upload-area" @click="triggerFileInput('rightEye2')">
                  <template v-if="!rightEye2Preview">
                  <i class="fas fa-camera camera-icon"></i>
                  <p>Click to Upload</p>
                  </template>
                  <img v-else :src="rightEye2Preview" alt="Right Eye 2">
              </div>
              <input type="file" ref="rightEye2" @change="handleRightEye2Upload" accept="image/*" class="hidden-input">
              <button v-if="rightEye2Preview" @click="removeImage('rightEye2')" class="remove-btn">Remove</button>
              </div>
          </div>
          </div>
      </div>

      <div class="action-section text-center mt-4" v-if="!isUploading">
          <button @click="handleUpload" class="upload-btn">
            Upload
          </button>
      </div>

      <div v-if="isUploading" class="progress-container">
        <div class="progress">
          <div 
            class="progress-bar" 
            role="progressbar"
            :style="{ width: `${uploadProgress}%` }"
            :aria-valuenow="uploadProgress"
            aria-valuemin="0" 
            aria-valuemax="100"
          >
            {{ uploadProgress }}%
          </div>
        </div>
        <div class="text-center mt-2">
          Uploading: {{ uploadedFiles }} of {{ totalFiles }} files
        </div>
      </div>

  </div>
</template>

<style scoped>
.upload-btn{
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 12px;
    border: none;
    font-size: 20px;
    padding: 6px;
    padding-left:20px;
    padding-right:20px;
}

.upload-btn:hover{
    background-color: var(--primary-hover); 
}

.image-container {
  display: grid;
  grid-gap: 20px;
  margin-top: 15px;
}

.image-upload-box {
  width: 100%;
  text-align: center;
}

.upload-area {
  width: 200px;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
  transition: border-color 0.3s;
}

.upload-area:hover {
  border-color: #4CAF50;
}

.camera-icon {
  font-size: 40px;
  color: #666;
  margin-bottom: 10px;
}

.upload-area p {
  margin: 0;
  color: #666;
}

.upload-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.hidden-input {
  display: none;
}

.remove-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-btn:hover {
  background-color: #c82333;
}

.images-section {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin: 20px 0;
  }
  
  .left-eyes, .right-eyes {
    flex: 1;
    width: 50%;
  }
  
  .left-eyes h3, .right-eyes h3 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
</style>

<style scoped>
.images-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
}

.left-eyes,
.right-eyes {
  flex: 1;
  min-width: 280px;
}

.image-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.image-upload-box {
  flex: 1;
  min-width: 130px;
}

.upload-area {
  aspect-ratio: 1;
  width: 100%;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.upload-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .images-section {
    flex-direction: column;
  }
  
  .left-eyes,
  .right-eyes {
    width: 100%;
  }
}

.progress-container {
  margin: 20px 0;
}

.progress {
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
  color: white;
  text-align: center;
  line-height: 20px;
}
</style>

<!-- Auto COmplete -->
<style scoped>

.card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-color:white;
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.autocomplete-wrapper {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.autocomplete-item {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.autocomplete-item:hover {
  background: #f8f9fa;
}

.patient-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patient-id {
  color: #6c757d;
  font-size: 0.9em;
}
</style>

<!-- Patients -->
<style scoped>
.patient-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.patient-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.patient-header i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.patient-header h3 {
  margin: 0;
  color: #2c3e50;
}

.patient-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.detail-value {
  font-size: 1.1rem;
  color: #2c3e50;
  font-weight: 600;
}

@media (max-width: 768px) {
  .patient-details-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .detail-item {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .detail-item:last-child {
    border-bottom: none;
  }
}

</style>


