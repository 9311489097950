<template>
    <div class="container mt-5 mb-2">
        <div class="page-header">
            <div class="header-left">
              <button class="back-btn" @click="goBack">
                <i class="fas fa-arrow-left"></i>
              </button>
            </div>
            <h2 class="header-title">
              <slot name="icon"></slot>
              {{ title }}
            </h2>
            <div class="header-right">
              <slot name="actions"></slot>
            </div>
        </div>
    </div>
  </template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.page-header {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  position: relative;
}

.header-left {
  position: absolute;
  left: 0;
}

.back-btn {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--primary-color);
  transition: all 0.2s ease;
}

.back-btn:hover {
  transform: translateX(-2px);
}

.header-title {
  margin: 0 auto;
  color: #2c3e50;
  font-size: 2rem;
  text-align: center;
}

.header-right {
  position: absolute;
  right: 1.5rem;
}

@media (max-width: 768px) {
  .page-header {
    padding: 1rem;
  }
  
  .header-title {
    font-size: 1.6rem;
  }
}
</style>

<style scoped>
.back-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #2c3e50;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.back-btn:hover {
  background: #e9ecef;
  transform: translateX(-2px);
}

.back-btn i {
  color: var(--primary-color);
  font-size: 20px;
}

.header-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 auto;
  }
</style>
