
<template>

    <!-- LOOPING TABLE CASES -->
    <transition name="fading">
        <Loading v-if="isLoading"/>
    </transition>

    <TitleHeader :title="`Patient List`">
      <template #icon>
        <BandageIcon />
      </template>
    </TitleHeader>

    <div class="container platform-size my-1">

      <SearchBar
      searchPlaceholder="Search Users Here.."
      buttonPlaceholder="Search"
      @OutputSearch="get_search_query"
      class="mb-3"
      />

      <div class="card round-card">
          <div class="card-body">

            <div class="flex-md ">
              <div class="row mx-1 mb-2">
                <button class="btn" style="background-color: var(--primary-color); color: white; padding:5px;" @click="$router.push('patients/register')"><i class="fa-solid fa-plus"></i> Register Patients</button>
              </div>
            </div>

            <Table :input_object="render_data">
              <template v-slot="{ data }">
                <div class="patient-card">
                  <div class="card-content">
                    <div class="card-header">
                      <div class="patient-name">
                        <h5>{{ data.PatientName.toUpperCase() }}</h5>
                      </div>
                      <div class="action-buttons">
                        <button class="action-btn" @click="goto_upload(data.PatientUuid)">
                          <i class="fa-solid fa-upload"></i>
                          <span class="action-text">Upload</span>
                        </button>
                        <button class="action-btn" @click="goto_editor(data.PatientUuid)">
                          <i class="fa fa-pencil-square-o"></i>
                          <span class="action-text">Edit</span>
                        </button>
                        <button class="action-btn" @click="goto_case(data.PatientUuid)">
                          <i class="fa fa-book"></i>
                          <span class="action-text">Sessions</span>
                        </button>
                      </div>
                    </div>
                
                    <div class="info-grid">
                      <div class="info-item">
                        <i class="fa-solid fa-calendar"></i>
                        <span>DOB: {{ data.PatientDOB.split(' ')[0] }}</span>
                      </div>
                      <div class="info-item">
                        <i class="fa-solid fa-clock"></i>
                        <span>Age: {{ data.PatientAgeAtScreening }} years</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Table>

          </div>

      </div>
    </div>

    





</template>

<script>
import Loading from "@/components/Loading.vue";
import Modal from "@/components/Modal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Table from "@/components/TableBorderless.vue";
import { getAllPatient } from "@/services/patientServices";
import {BandageIcon} from 'lucide-vue-next'
import TitleHeader from '@/components/TitleHeaderNoBtn.vue'


export default{
components: {
Loading,
Modal,
SearchBar,
Table,
BandageIcon,
TitleHeader
},

created(){
this.get_data();
console.warn("Created DOM!");
},
data() {
    return {
    raw_data : [],
    render_data : [],
    current_page : 0,
    page_limit : 10,
    isAddUser: false,
    isLoading: false,
    search_query: '',
    click_categories: false,

    modal :{
        remove_question: false,
        text: '',
    },
    hold :{
        question_key: '',
    }

    }
},
computed: {

},
methods: {

get_search_query(search_query){
  this.render_data = this.raw_data;

  let list = Object.values(this.render_data);
  let query = search_query ? search_query.toLowerCase() : ''; // Ensure query is always a string

    let filtered_data = list.filter(data => {
      const matchesName = data.PatientName.toLowerCase().includes(query);
      const matchesIC = data.ICNumber.toLowerCase().includes(query);
      const matchesPhoneNumber = data.PhoneNumber.toLowerCase().includes(query);
      const matchesCreatedAt = data.created_at      .toLowerCase().includes(query);
      // Return true if any of the conditions are met
      return matchesName || matchesIC  || matchesPhoneNumber  || matchesCreatedAt
    });

    this.render_data = filtered_data;
},

async get_data(){
    this.isLoading = true
    const result = await getAllPatient();

    console.log("result",result);

    if(result.status == 'success' && result.message != 'No patients found'){ 
        this.isLoading = false
        this.raw_data = result.data;
        this.render_data = result.data.reverse(); 
    }
    else{
        this.isLoading = false
    }
},

process_render_data(raw_data){
    this.render_data = [];
    for (const [key, value] of Object.entries(raw_data)) {
      this.render_data.push(value);
    }
},

goto_editor(input_id){
    this.$router.push({ name: 'PatientsEdit', params: { uuid: input_id} });
},

goto_upload(input_id){
  this.$router.push({ name: 'RetinaUploadPatient', params: { patientUuid: input_id} });
},

goto_case(input_id){
    this.$router.push({ name: 'CasePatient', params: { uuid: input_id} });
},

count_obj(data){
  let count = 0;
  for (const [key, value] of Object.entries(data)) {
    count++
  }
  return count;
},

}
}
</script>

<style scoped>
.patient-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(15, 186, 178, 0.08);
  border-left: 3px solid var(--primary-color);
}

.card-content {
  padding: 1rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.patient-name h5 {
  color: var(--primary-color);
  font-size: 1.1rem;
  margin: 0;
  font-weight: 600;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  background: white;
  color: var(--primary-color);
  font-size: 0.9rem;
  transition: all 0.2s;
}

.action-btn:hover {
  background: var(--primary-color);
  color: white;
}

.info-grid {
  display: flex;
  gap: 1.5rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.info-item i {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .card-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .action-buttons {
    width: 100%;
  }

  .action-text {
    display: none;
  }
  
  .action-btn {
    padding: 0.4rem;
    width: 36px; /* Fixed width for icons only */
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .patient-card{
    width: 125%;
    margin-left: -12.5%;
    margin-right: -12.5%;
  }
}
</style>

<style scoped>
/* Vue Transition - Fade */
.fading-enter-active,
.fading-leave-active {
  transition: opacity 0.3s ease;
}

.fading-enter-from,
.fading-leave-to {
  opacity: 0;
}
</style>






