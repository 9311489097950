import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';

const BACKEND_URL = 'https://drmatabackend-584749671098.asia-southeast1.run.app';

export const uploadCaseGrading = async (imageFile, patientUuid, sessionUuid, eyeType) => {
    const authStore = useAuthStore();
    const formData = new FormData();
    
    formData.append('image_file', imageFile);
    formData.append('PatientUuid', patientUuid);
    formData.append('SessionUuid', sessionUuid);
    formData.append('EyeType', eyeType); // Added EyeType parameter (0 for Left, 1 for Right)
  
    try {
      const response = await axios.post(
        `${BACKEND_URL}/drmata_user/case-grading`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${authStore.accessToken}`
          }
        }
      );
      return response.data;
    } catch (error) {
        throw error;
    }
    
  };
