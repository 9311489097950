
<template>
    <div v-if="show" class="ai-overlay">
      <div class="overlay-content">
        <div class="header">
            <h2 class="header-title">AI Summary</h2>
            <button class="close-btn text-black px-2" @click="closeOverlay">×</button>
        </div>
        <div class="content">
          <div v-if="isStreaming" class="status"><b>{{ streamStatus }}</b></div>
          <div v-html="compiledMarkdown(this.aiResponse.Response)" class="markdown-content"></div>
        </div>
      </div>
    </div>
</template>

<script>
import { fetchEventSource } from '@microsoft/fetch-event-source'
import MarkdownIt from 'markdown-it'

const md = new MarkdownIt({
    breaks: true,
    typographer: true,
    linkify: true
  }).enable(['list', 'emphasis', 'heading'])

export default {
  name: 'AIOverlay',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    session_data: {},
    patient_data: {}
    
  },
  data() {
    return {
    aiResponse: {
        Response: '',
        Filename: '',
        Page: '',
        URL: '',
        Suggestions: {}
    },
      full_string: '',
      isStreaming: false,
      streamStatus: '',
      abortController: null,
      md: new MarkdownIt(),
      CPG_ID: '3d0b00a3-5668-4540-b617-3e76d0424ef5',
    }
  },
  computed: {
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.startStream(this.promptString())
      }
    }
  },
  methods: {
    closeOverlay() {
      this.$emit('update:show', false)
      this.reset_response()
      if (this.abortController) {
        this.abortController.abort()
      }
    },
    reset_response() {
      this.aiResponse = {
            Response: '',
            Filename: '',
            Page: '',
            URL: '',
            Suggestions: {}
        },
      this.full_string = '';
      this.isStreaming = false
      this.streamStatus = ''
    },
    handleStreamMessage(event) {
        try {
            const data = JSON.parse(event.data)
            if (data.Response) {
            this.streamStatus = 'Generating summary...';
            this.aiResponse.Response += data.Response;
            this.aiResponse.Response = this.aiResponse.Response.replace('undefined', '');
            this.aiResponse.Response = this.aiResponse.Response.replace('Response":', '');
            this.full_string += data.Response;
            }
            if (data.Filename) {
            this.full_string += data.Filename;
            }
            if (data.Page) {
            this.full_string += data.Page;
            }
            if (data.URL) {
            this.full_string += data.URL;
            }
            if (data.Suggestions) {
            this.full_string += data.Suggestions;
            }
        } catch (error) {
            this.full_string = '';
            console.error('Error parsing stream message:', error)
            this.handleStreamError(error)
            // Force stop streaming on parse error
            if (this.abortController) {
            this.abortController.abort()
            }
        }
    },
    handleStreamClose() {
        this.isStreaming = false
        this.streamStatus = 'Stream ended'
        
        try {
            console.log(this.full_string)
            this.full_string = this.full_string.trim();            
            this.aiResponse = JSON.parse('{"' + this.full_string);
        } catch (error) {
            console.error('Error parsing final response:', error)
            this.streamStatus = 'Error: Failed to process response'
        }

        this.full_string = ''
        this.abortController = null
    },
    handleStreamError(error) {
        this.isStreaming = false
        this.streamStatus = 'Error: ' + error.message
        console.error('Stream error:', error)
        this.abortController = null
        this.full_string = ''
    },
    startStream(query) {
      this.reset_response()
      this.isStreaming = true
      this.streamStatus = 'Getting All Data...'
      this.full_string = ''

      if (this.abortController) {
        this.abortController.abort()
      }

      this.abortController = new AbortController()

      const payload = {
        query,
        book_id: this.CPG_ID,
        is_testing: false
      }

      fetchEventSource('https://langserve-copilot-dp7nm56cca-as.a.run.app/v4/cpg/selective_search', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer Y4OgToJIvlQNqBN2vwHDXhI9E0jU6ZKryyhF7Q6yqgwhvVophf',
          'Content-Type': 'application/json',
          'Accept': 'text/event-stream'
        },
        body: JSON.stringify(payload),
        signal: this.abortController.signal,
        onopen(response) {
          return Promise.resolve()
        },
        onmessage: (event) => this.handleStreamMessage(event),
        onclose: () => this.handleStreamClose(),
        onerror: (error) => this.handleStreamError(error)
      })
    },

    formatDate(dateString) {
          if (!dateString) return '';
          
          const date = new Date(dateString);
          const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          
          const day = date.getDate();
          const monthName = months[date.getMonth()];
          const year = date.getFullYear();
          
          // Calculate age
          const today = new Date();
          let age = today.getFullYear() - date.getFullYear();
          const monthDiff = today.getMonth() - date.getMonth();
          
          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
            age--;
          }
          
          return `${day} ${monthName} ${year} (${age} Years Old)`;
    },
    

    promptString(){
        console.log(this.session_data);
        console.log(this.patient_data);

        let prompt = `
Patient Details:
Name: ${this.patient_data.PatientName}
${ this.patient_data.PatientDOB ? 'DOB: ' + this.formatDate(this.patient_data.PatientDOB) : ''}

Variables: 
X: Stages of retinopathy

Left eye (Confidence level: ${(this.session_data.averageLeftEyeConfident * 100).toFixed(1)}%): 
X: ${ this.session_data.highestLeftEyeGrading.replace('_', ' ') }

Right eye (Confidence level: ${(this.session_data.averageRightEyeConfident * 100).toFixed(1)}%):
X: ${ this.session_data.highestRightEyeGrading.replace('_', ' ') }
 
{
-   	Grade_0/ Normal= No DR;
-   	For Grade_1, display “Follow up= Yes, Frequency of follow up= Repeat fundus photo within 6 months, referral to ophthalmologist= Referral not needed”;
-   	For Grade_2, display “Follow up= Yes, Frequency of follow up= Refer to ophthalmologist, referral to ophthalmologist= Referral needed”;  }
 
What is the next course of action? Fill up the list below for left and right eye:
 
·  	Grade of DR: (Only accept diagnosis if confidence level ≥60%; if confidence level <60%, Display: “Kindly retake fundus picture”) (display confidence level here)
·  	Action plan (Don’t show this point if confidence level <60%)
o   Follow up: (Yes/No) (Follow up: Yes, if: “refer to ophthalmologist, refer urgently to ophthalmologist”)
o   Frequency of follow up (no need to display if referral to ophthalmologist required) (If No DR, display: “Repeat fundus photo within a year”):
o   Referral to Ophthalmologist: (Referral needed/ referral not needed)
o   Suggested treatment plan (table 5: Summary of Treatment for Diabetic Retinopathy):
(if Grade_0/ Normal and Grade_1, display: “No specific treatment needed, continue regular monitoring and control of blood glucose, blood pressure, and serum lipids”)
(if Grade_2, display: “Referral to an ophthalmologist for further evaluation and potential treatment options such as laser photocoagulation or intraocular anti-VEGF if necessary”)
`

    console.log(prompt)
    return prompt
    },

    compiledMarkdown(content) {
        if (!content) return '';

        if(content == "Unfortunately, I wasn't able to find the relevant information from the CPG database. Would you like me to search in Worldwide Search?"){
            content = "Sorry, the question is out of CPG handbook content"
          this.aiResponse.Suggestions = null
          this.aiResponse.Filename = {}
        }

        if(content.includes("Unfortunately, I wasn't able to find the relevant information from the CPG database. Would you like me to search in Worldwide Search?")){
                content = content.replace("Unfortunately, I wasn't able to find the relevant information from the CPG database. Would you like me to search in Worldwide Search?", "")
        }
        let processedContent = content
        .replace(/\\n/g, '\n')                    // Convert literal \n to actual newlines
        .replace(/\n{3,}/g, '\n\n')              // Reduce multiple newlines to max 2
        .replace(/\n\n(\d+\.)/g, '\n\n\n$1')     // Add spacing before numbered lists
        .replace(/(\*\*.*?\*\*:)\n\s*/g, '$1 ')  // Clean spacing after bold headers
        .replace(/\n\s*-\s*/g, '\n- ')           // Normalize list item spacing
        .trim();

        return md.render(processedContent);
    }
  }
}
</script>

<style scoped>
.ai-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay-content {
  background-color: white;
  width: 85%;
  max-width: 800px;
  max-height: 60vh;
  border-radius: 8px;
  padding: 20px;
  position: relative;
}

@media screen and (max-width: 768px) {
    .overlay-content {
        width: 90%;
        max-height: 85vh;
    }
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.content {
  overflow-y: auto;
  max-height: calc(80vh - 60px);
}

.status {
  color: #666;
  margin-bottom: 10px;
}

.markdown-content {
  line-height: 1.6;
}

.markdown-content :deep(p) {
  margin-bottom: 1em;
}

.markdown-content :deep(code) {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}
</style>

<style scoped>
.ai-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay-content {
  background-color: white;
  width: 80%;
  max-width: 800px;
  height: 90vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  flex-shrink: 0;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.markdown-content {
  line-height: 1.6;
}
</style>

<style scoped>
.header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.close-btn {
  position: absolute;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.header-title {
  flex: 1;
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
  color: #2c3e50;
}
</style>

<style scoped>
.markdown-content :deep(table) {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
}

.markdown-content :deep(th),
.markdown-content :deep(td) {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.markdown-content :deep(th) {
  background-color: #f5f5f5;
}

.markdown-content :deep(blockquote) {
  border-left: 4px solid var(--primary-color);
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  background: #f8f9fa;
}

.markdown-content :deep(hr) {
  border: none;
  border-top: 1px solid #eee;
  margin: 1rem 0;
}
</style>