<template>
  <TitleHeaderNoBtn title="CPG Assistant">
    <template #icon>
      <BrainCircuitIcon />
    </template>
  </TitleHeaderNoBtn>

  <div class="container">
    <!-- Header -->
    <div class="header">
      <h3>Diabetic Retinopathy Screening</h3>
      <p class="subtitle">Your AI guide for diabetic retinopathy screening guidelines</p>
    </div>

    <div class="content-wrapper">
      <!-- Chat Interface -->

      <div class="chat-container" :class="{ 'with-pdf': isPdfOpen }">
        <!-- Query Display -->
        <div style="text-align: left; margin-bottom: 10px;">
          <h4><b>{{ Query }}</b></h4>
        </div>
        <!-- Messages Display -->
        <div class="messages" ref="messageContainer">
          <div v-if="aiResponse.Response" class="message ai-message">
            <div class="message-content markdown-body" v-html="renderedMarkdown"></div>
            
            <span v-if="aiResponse.Filename.length>0"><b>Source:</b></span> 
            <div v-for="(filename, key) in aiResponse.Filename" v-if="aiResponse.Filename" class="source-info">
              <button @click="togglePdfViewer(aiResponse.Page[key])" class="source-button">
                {{ filename }} (Page {{ aiResponse.Page[key] }})
              </button>
            </div>
            <div v-if="aiResponse.Suggestions" class="suggestions-section">
              <span class="mb-1" v-if="aiResponse.Suggestions"><b>Suggestions:</b></span> 
              <div class="suggestion-chips">
                <button 
                  v-for="(suggestion, index) in aiResponse.Suggestions" 
                  :key="index"
                  @click="handleSuggestionClick(suggestion)"
                  class="suggestion-chip"
                >
                  {{ suggestion }}
                </button>
              </div>
            </div>
          </div>
          
          <!-- Stream Status -->
          <div v-if="isStreaming" class="stream-status">
            <looping-rhombuses-spinner
              :animation-duration="2500"
              :rhombus-size="15"
              color="#2cbbb2"
            />
            <span>{{ streamStatus }}</span>
          </div>
        </div>

        <!-- Input Area -->
        <div class="input-area">
          <textarea 
            v-model="userQuery" 
            placeholder="Ask about diabetic retinopathy screening..."
            @keyup.enter.exact="handleSubmit"
            :disabled="isStreaming"
            rows="3"
          ></textarea>
          <button 
            @click="handleSubmit" 
            :disabled="isStreaming || !userQuery.trim()"
            class="submit-button"
          >
            <span v-if="!isStreaming">Send</span>
            <div v-else class="button-spinner">Fetch</div>
          </button>
        </div>
      </div>
      <!-- PDF Viewer -->
      <PdfViewer
        v-model:isOpen="isPdfOpen"
        v-model:page="pdfPage"
        :source="'https://storage.googleapis.com/copilot_cpg_bucket/3d0b00a3-5668-4540-b617-3e76d0424ef5.pdf'"
        :page="pdfPage"
      />
    </div>
  </div>
</template>


<script>
  import { fetchEventSource } from '@microsoft/fetch-event-source';
  import VuePdfEmbed from 'vue-pdf-embed';
  import { useWindowSize } from '@vueuse/core';
  import PdfViewer from '@/pages/CPG/PDFViewer.vue';
  import TitleHeaderNoBtn from '@/components/TitleHeaderNoBtn.vue';
  import MarkdownIt from 'markdown-it'
  import { BrainCircuitIcon } from 'lucide-vue-next'
  import { LoopingRhombusesSpinner } from 'epic-spinners'

  const md = new MarkdownIt({
    breaks: true,
    typographer: true,
    linkify: true
  }).enable(['list', 'emphasis', 'heading'])
  
  export default {
    name: 'CPGChat',
    components: {
      PdfViewer,
      TitleHeaderNoBtn,
      BrainCircuitIcon,
      LoopingRhombusesSpinner
    },
  
    setup() {
      const { width } = useWindowSize();
      return { width };
    },
      
    data() {
      return {
        userQuery: '',
        Query: '',
        streamStatus: '',
        isStreaming: false,
        abortController: null,
        aiResponse: {
          Response: '',
          Filename: '',
          Page: '',
          URL: '',
          Suggestions: {}
        },
        CPG_ID: '3d0b00a3-5668-4540-b617-3e76d0424ef5',
        full_string: '',
        isPdfOpen: false,
        pdfSource: null,
        pdfPage: 1,
      }
    },

    computed: {
      isMobile() {
        return this.width < 768;
      },
      
      pdfHeight() {
        return this.isMobile ? '90vh' : '100%';
      },

      renderedMarkdown() {
        if (!this.aiResponse.Response) return '';

        if(this.aiResponse.Response == "Unfortunately, I wasn't able to find the relevant information from the CPG database. Would you like me to search in Worldwide Search?"){
          this.aiResponse.Response = "Sorry, the question is out of CPG handbook content"
          this.aiResponse.Suggestions = null
          this.aiResponse.Filename = {}
        }

        let processedContent = this.aiResponse.Response
        .replace(/\\n/g, '\n')                    // Convert literal \n to actual newlines
        .replace(/\n{3,}/g, '\n\n')              // Reduce multiple newlines to max 2
        .replace(/\n\n(\d+\.)/g, '\n\n\n$1')     // Add spacing before numbered lists
        .replace(/(\*\*.*?\*\*:)\n\s*/g, '$1 ')  // Clean spacing after bold headers
        .replace(/\n\s*-\s*/g, '\n- ')           // Normalize list item spacing
        .trim();
        
        return md.render(processedContent);
      }
      
    },
  
    methods: {
      handleSubmit() {
        if (!this.userQuery.trim() || this.isStreaming) return;
        this.startStream(this.userQuery);
        this.Query = this.userQuery;
      },
  
      handleSuggestionClick(suggestion) {
        this.userQuery = suggestion;
        this.handleSubmit();
      },
  
      startStream(query) {
        // Reset states
        this.reset_response();
        this.isStreaming = true;
        this.streamStatus = 'Initiating search...';
        
        // Abort previous request if exists
        if (this.abortController) {
          this.abortController.abort();
        }
        
        this.abortController = new AbortController();
  
        const payload = {
          query,
          book_id: this.CPG_ID,
          is_testing: false
        };
  
        fetchEventSource('https://langserve-copilot-dp7nm56cca-as.a.run.app/v4/cpg/selective_search', {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer Y4OgToJIvlQNqBN2vwHDXhI9E0jU6ZKryyhF7Q6yqgwhvVophf',
            'Content-Type': 'application/json',
            'Accept': 'text/event-stream'
          },
          body: JSON.stringify(payload),
          signal: this.abortController.signal,
          onopen(response) {
            // Custom handler to accept text/plain content type
            return Promise.resolve();
          },
          onmessage: (event) => this.handleStreamMessage(event),
          onclose: () => this.handleStreamClose(),
          onerror: (error) => this.handleStreamError(error)
        });
      },
  
      handleStreamMessage(event) {
        try {
          const data = JSON.parse(event.data);
          console.log(data);
          
          // Check for error in response data
          if (data.error) {
            this.handleStreamError(data.error);
            return;
          }
          
          if (data.Response) {
            this.streamStatus = 'Receiving response...';
            this.aiResponse.Response += data.Response;
            this.aiResponse.Response = this.aiResponse.Response.replace('undefined', '');
            this.aiResponse.Response = this.aiResponse.Response.replace('Response":', '');
            this.full_string += data.Response;
          }
          if (data.Filename) {
            this.streamStatus = 'Loading source information...';
            this.full_string += data.Filename;
          }
          if (data.Page) {
            this.full_string += data.Page;
          }
          if (data.URL) {
            this.streamStatus = 'Processing suggestions...';
            this.full_string += data.URL;
          }
          if (data.Suggestions) {
            this.streamStatus = 'Processing suggestions...';
            this.full_string += data.Suggestions;
          }
        } catch (error) {
          console.error('Stream parsing error:', error);
          // Stop the stream and abort the controller
          this.handleStreamError(error);
          if (this.abortController) {
            this.abortController.abort();
          }
          // Reset streaming state
          this.isStreaming = false;
          this.streamStatus = '';
        }
      },

      handleStreamClose() {
        this.isStreaming = false;
        this.streamStatus = '';
        this.userQuery = '';

        console.log(this.full_string);
        this.aiResponse = JSON.parse('{"' + this.full_string);
        console.log(this.aiResponse);
      },

      handleStreamError(error) {
        console.error('Stream error:', error);
        this.isStreaming = false;
        this.streamStatus = 'An error occurred. Please try again.';
        if (this.abortController) {
          this.abortController.abort();
        }
      },
  
      generateBookUrl(page) {
        // Implement your URL generation logic here
        return `https://your-pdf-viewer-url/${this.CPG_ID}?page=${page}`;
      },

      //FOR PDF  
      togglePdfViewer(file_page) {

        console.log(this.aiResponse.URL[1])
        this.isPdfOpen = true;
        if (this.isPdfOpen) {
          this.pdfSource = this.aiResponse.URL[1];
          this.pdfPage = parseInt(file_page);
          console.log(this.pdfSource, this.pdfPage);
        }
      },

      reset_response(){
        console.warn("reset_response()");
        this.full_string = []
        this.aiResponse = {
          Response: '',
          Filename: '',
          Page: '',
          URL: '',
          Suggestions: null
        }
      }
    }
  }
</script>
  
<style scoped>
  .cpg-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Inter', sans-serif;
  }
  
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .header h1 {
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
  
  .subtitle {
    color: #666;
    font-size: 1.1rem;
  }
  
  .chat-container {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .messages {
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
    padding: 1rem;
  }
  
  .message {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background: #f9fafe;
  }
  
  .ai-message {
    background: #f9fafe;
  }
  
  .source-info {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #666;
  }
  
  .input-area {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  textarea {
    flex: 1;
    padding: 1rem;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    resize: vertical;
    min-height: 60px;
  }
  
  .submit-button {
    padding: 0 1.5rem;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .submit-button:hover {
    background: var(--primary-hover);
  }
  
  .submit-button:disabled {
    background: #ccc;
  }
  
  .suggestions {
    margin-top: 2rem;
  }
  
  .suggestion-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .suggestion-chip {
    padding: 0.5rem 1rem;
    background: #e0e0e0;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .suggestion-chip:hover {
    background: #bdbdbd;
  }
  
  .loading-dots {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 20px;
  }
  
  .stream-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-style: italic;
  }
</style>
  
<style scoped>
.cpg-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.content-wrapper {
  display: flex;
  gap: 2rem;
  max-width: 1800px;
  margin: 0 auto;
}

.chat-container {
  flex: 1;
  transition: all 0.3s ease;
}

.chat-container.with-pdf {
  max-width: 50%;
}

.pdf-container {
  position: fixed;
  background: white;
  box-shadow: -2px 0 10px rgba(0,0,0,0.1);
  height: 100vh;
  width: 50%;
  right: 0;
  top: 0;
  padding: 1rem;
  z-index: 1000;
}

.pdf-container.mobile {
  width: 100%;
}

.close-pdf {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
}

.source-link {
  background: none;
  border: none;
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

/* Animation */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .chat-container.with-pdf {
    max-width: 100%;
  }
  
  .pdf-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
  }
}

/* Add your existing styles here */
</style>

<style scoped>
.markdown-body table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
}

.markdown-body th,
.markdown-body td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.markdown-body th {
  background-color: #f5f5f5;
}

.source-button {
  background: #f9effa;
  border: none;
  color: #6e2b92;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.source-button:hover {
  background: #6e2b92;
  color: #f9effa;
  transform: translateY(-1px);
}

.suggestions-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0,0,0,0.1);
}

.suggestions-section h4 {
  color: #666;
  margin-bottom: 0.8rem;
  font-weight: 500;
}
</style>