<template>
  <div>
    <!-- Conditionally show LeftNavBar for non-login pages only after checking the route -->
    <LeftNavBar v-if="!isLoginPage">
      <router-view></router-view>
    </LeftNavBar>

    <!-- Directly show Login component for /login page -->
    <router-view v-else></router-view>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { computed } from 'vue';
import LeftNavBar from './components/LeftNavBar.vue';

// Get the current route
const route = useRoute();
const router = useRouter();

// Compute a reactive property to check if the current page is the login page
const isLoginPage = computed(() => route.path === '/login');

// Prevent LeftNavBar from rendering at all if on login page
</script>
