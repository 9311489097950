<template>
    <div class="container platform-size mt-2">

        <div class="text-center mx-auto mb-2 p-2" style="width:fit-content; font-size: 25px;" >Profile</div>

        <div v-if="isSuccess" class="mx-auto p-1 px-2 text-white" style="width:fit-content; font-size: 13px; background-color: #3ac47dbf; border-radius: 12px;" >Successfully Updated Your Account</div>
        <div class="container platform-size">
            <!-- Patient Details -->
            <div class="container platform-size mb-4">
                  <div class="card" style="padding:10px; border-radius:12px; ">
                    <table class="table">
                        <tr>
                          <th class="col-2">Full Name</th>
                          <td class="col-10">
                            <input class="form-control" :disabled="!isEditing" v-model="user_data.FullName"/>
                          </td>
                        </tr>

                         <tr>
                          <th class="col-2">Profession</th>
                          <td class="col-10">
                            <input class="form-control" :disabled="!isEditing" v-model="user_data.Profession"/>
                          </td>
                        </tr>

                        <tr>
                            <th class="col-2">Specialization</th>
                            <td class="col-10">
                              <input class="form-control" :disabled="!isEditing" v-model="user_data.Specialization"/>
                            </td>
                        </tr>

                        <tr>
                            <th class="col-2">License No</th>
                            <td class="col-10">
                              <input class="form-control" :disabled="!isEditing" v-model="user_data.LicenseNo"/>
                            </td>
                        </tr>

                        <tr>
                            <th class="col-2">Contact Number</th>
                            <td class="col-10">
                              <input class="form-control" :disabled="!isEditing" v-model="user_data.ContactNumber"/>
                            </td>
                        </tr>


                    </table>
                    <div class="mx-auto">
                        <button class="btn btn-success" @click="isEditing = true" v-if="!isEditing">Edit</button>
                        <button class="btn btn-cancel mx-2" @click="isEditing = false" v-if="isEditing">Cancel</button>
                        <button class="btn btn-success mx-2" @click="submit()" v-if="isEditing">Save</button>
                    </div>
                  </div>
            </div>

        </div>

      </div>
</template>

<script>
import { getUser, editUser } from '@/services/profileServices';

export default{

  created(){
    this.getUser();
  },
  data() {
    return {
        user_data: {
            FullName: "",
            Profession: "",
            LicenseNo: "",
            Specialization: "",
            ContactNumber: ""
        },
        payload: {},
        isEditing: false,
        isSuccess: false,
    }
  },
  computed: {

  },
  methods: {


    async getUser(){
        const result = await getUser();
        console.log(result.user_info);
        this.convert_data(result.user_info)
    },

    convert_data(data){
        this.user_data = {
            FullName: data.FullName,
            Profession: data.Profession,
            LicenseNo: data.LicenseNo,
            Specialization: data.Specialization,
            ContactNumber: data.ContactNumber
        }
    },

    async submit(){
        console.log(this.user_data);
        const result = await editUser(this.user_data);

        console.log(result);
        if(result.Status == 'Success'){
            this.isEditing = false;
            this.isSuccess = true
        }

        setTimeout(() => {this.isSuccess = false;}, 4000);

    }

  }
}

</script>

<style scoped>

.container{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
}

.modal-container{
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

table{
  border-radius : 5px;
}

.platform-size{
  width:100% !important;
  padding:10px !important;
}

.nav-group{
  padding:0px 10px;
  background-color: #f8f9fad9 !important;
}

.logo-img{
  width:17%
}

.navbar-brand{
  font-size: 26px !important;
  font-weight: 600;
}

.row .card .card-body{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card-body .card-title{
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
}

.age-title{
  opacity: 0.8;
  font-weight: 600 !important;
  font-size: 20px;
}

.gender-title{
  opacity: 0.8;
  font-weight: 600 !important;
  font-size: 19px;
}

.card {
    box-shadow: none;
}

.sec1{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.sec1 .col-6{
  width: 49%;
}

.card-body .age-group{
  flex-direction: row !important;
  padding: 15px 20px;
}

.card-age-title{
  width: 100%;
  padding-left: 0.8rem;
}

.gender-type{
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: black;
  text-decoration: unset;
}

.card-body .card-subtitle{
  margin-top: 0;
  margin-bottom: 0;
  width: 55%;
  text-align: center;
  font-size: 15px;
}

.col-lg-12:first-child .gender-group{
  margin-bottom: 8px;
}

.card-body .card-subtitle a{
  display: block;
  width: 100%;
}

.col-ms:nth-child(1) a{
  background-color:#3ac47dbf;
}

.col-ms:nth-child(2) a{
  background-color:#3f6ad8bf;
}

.col-ms:nth-child(3) a{
  background-color:#f7b924bf;
}


.col-ms:nth-child(4) a{
  background-color:#d92550e6 ;
}

.col-ms:nth-child(1) .card{
  border-bottom: 3px solid #3ac47dbf;
  border-radius: 0.25rem;
}

.col-ms:nth-child(2) .card{
  border-bottom: 3px solid #3f6ad8bf;
  border-radius: 0.25rem;
}

.col-ms:nth-child(3) .card{
  border-bottom: 3px solid #f7b924bf;
  border-radius: 0.25rem;
}

.col-ms:nth-child(4) .card{
  border-bottom: 3px solid #d92550e6;
  border-radius: 0.25rem;
}

.age .card-body{
  padding: 14px 10px 14px 10px;
}

.table thead{
  font-weight: 600;
    text-transform: uppercase;
    border-bottom: 2px solid #dbdbdb;
}

.table thead td{
  opacity: 0.9;
}

.btn-success {
  background-color: var(--primary-color) !important;
  border: none !important;
  font-weight: 500 !important;
  padding: 5px 25px !important;
  font-size: 20px !important;
}

.btn-success:hover {
    background-color: var(--primary-hover) !important;
}

.btn-cancel {
    background-color: #d92550e6 !important;
    color:white;
    width: auto;
    border: none !important;
    font-weight: 500 !important;
    padding: 5px 25px !important;
    font-size: 20px !important;
  }

  .btn-cancel:hover {
      background-color: #631729e6 !important;
  }

.page-link {
    color:black !important;
    opacity: 0.8;
}

.active>.page-link, .page-link.active {
    color: white !important;
    background-color:var(--primary-color) !important ;
    border-color:var(--primary-color) !important;
}

.case-group{
  display: inline-flex;
  align-items: center;
}

.case-title{
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.25rem;
}

.case-search{
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}

.case-search .form-control{
  margin: auto;
  width: 80%;
}

.triage td:first-child{
  color: #4DC99A;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.triage td:nth-child(2){
  color: #F7B924;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.triage td:nth-child(3){
  color: #FF802B;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.table-triage .first{
  color: #4DC99A;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.table-triage .second{
  color: #F7B924;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.table-triage .third{
  color: #FF802B;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}

.table-triage .fourth{
  color: #ff2929;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 15px;
}


.desktop-view{
  display: block;
}

.mobile-view{
  display: none;
}

.calender{
  width:auto;
  background-color: var(--primary-color);
  color:white;
  cursor:pointer;
}

.calender:hover{
  background-color: var(--primary-hover);
  color:white
}

@media (max-width: 785px) {

  .desktop-view{
    display: none;
  }

  .mobile-view{
    display: block;
  }

  .platform-size{
    width:100% !important;
    padding:8px !important;
  }

  .platform-size-nav{
    width:100% !important;
    padding:8px !important;
    border-radius: 0px !important;
  }

  .navbar-brand{
    margin-left:auto !important;
    margin-right:auto !important;
  }

  .d-flex{
    margin: auto;

    .btn{
      font-size: 12px !important;
    }
  }

  .nav-group{
    padding:0px;
  }

  .logo-img{
    width: 50%;
    margin: auto;
    padding: 8px 0 15px 0;
  }
}
@media (max-width: 1080px) {
  .age .card-body {
    min-height: 80px;
  }
}

.nav-btn {
  border-radius: 10px;
  padding: 10px 16px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  font-size: 15px;
}

.nav-btn.red{
  background-color: #d92550e6 !important;
}

.nav-btn.red:hover{
  background-color: #a11838e6 !important;
}


/* Style the active class */
.nav-btn.active {
  background-color: var(--primary-hover);
  color: white;
}

/* Style the buttons on mouse-over */
.nav-btn:hover {
  background-color: var(--primary-hover);
  color: white;
}

.queue-border{
  background-color: #3ac47dbf;
  border-radius: 9px;
  color: white;
  width: auto;
  padding: 2px;
}

.none{
  background-color: #d92550e6;
}


.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.absolute-right {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
}

.right-item {
  position: absolute;
  top: 10px;
  right: 0px;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  white-space: nowrap;
}

.absolute-left {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 2rem;
}

.left-item {
  position: absolute;
  top: 10px;
  left: 0px;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  white-space: nowrap;
}

.card-disclaimer {
  width: fit-content;
  padding: 6px;
  background-color: #fff3cd;
  border-radius: 4px;
  font-size: 12px;
}

.card-disclaimer p {
  margin: 0;
  font-size: 0.9em;
  color: #856404;
}

.button-icon{
  color: black;
  font-size: 32px;
  margin: 8px;
  line-height: 0;
  svg{
    margin-top: 8px;
  }
}

.button-icon:hover{
  color:var(--primary-hover);
  cursor: pointer;
}

.slidevertical-move,
.slidevertical-enter-active,
.slidevertical-leave-active{
  transition: all 0.5s ease;
}
.slidevertical-enter-from{
  opacity: 0;
  transform: translateY(-1000px);
}
.slidevertical-leave-to{
  opacity: 0;
  transform: translateY(1000px);
}
.slidevertical-leave-active{
  position:absolute;
}

.form-check-input{
  height: 23px !important;
  width: 45px !important;
  border-color:var(--primary-color) !important;
  cursor: pointer;
}
.form-check-input:checked{
  background-color:var(--primary-color) !important;
}


</style>
