<template>
  <div class="container platform-size my-1">
    <!-- IMAGE UPLOAD FORM -->
    <div class="card round-card mt-5">
      <div class="card-body">
        <label for="imageUpload">Upload an Image for Analysis</label>
        <input type="file" id="imageUpload" @change="onFileChange" class="form-control mb-2"/>
        <button class="btn green-btn" @click="analyzeImage">Analyze Image</button>
      </div>
    </div>

    <!-- RESULT DISPLAY -->
    <div v-if="combinedAbnormalityResult || yoloResult || abnormalityResult" class="card round-card mt-4">
      <div class="card-body text-center">
        <h4>Analysis Results</h4>

        <div v-if="combinedAbnormalityResult">
          <h5>Combined Abnormality Grading</h5>
          <p>Status: {{ combinedAbnormalityResult.status }}</p>
          <p>Grade: {{ combinedAbnormalityResult.grade }}</p>
          <img :src="'data:image/jpeg;base64,' + combinedAbnormalityResult.image" class="img-fluid mt-2" alt="Combined Abnormality Image"/>
        </div>

        <div v-if="yoloResult">
          <h5>YOLO Detection</h5>
          <img :src="'data:image/jpeg;base64,' + yoloResult.image" class="img-fluid mt-2" alt="YOLO Detection Image"/>
        </div>

        <div v-if="abnormalityResult">
          <h5>Abnormality Detection</h5>
          <p>Result: {{ abnormalityResult.prediction }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const BACKEND_URL = "https://drmatabackend-584749671098.asia-southeast1.run.app";

export default {
  data() {
    return {
      selectedImage: null,
      combinedAbnormalityResult: null,
      yoloResult: null,
      abnormalityResult: null
    };
  },
  methods: {
    onFileChange(event) {
      this.selectedImage = event.target.files[0];
    },
    async analyzeImage() {
      if (!this.selectedImage) {
        alert('Please select an image first.');
        return;
      }

      const formData = new FormData();
      formData.append('image', this.selectedImage);

      try {
        // Call Combined Abnormality Grading API
        const combinedAbnormalityRes = await axios.post(`${BACKEND_URL}/vision/combined-abnormality-grading`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        this.combinedAbnormalityResult = combinedAbnormalityRes.data;

        // Call YOLO Detection API
        const yoloRes = await axios.post(`${BACKEND_URL}/vision/detection`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        this.yoloResult = yoloRes.data;

        // Call Abnormality Detection API
        const abnormalityRes = await axios.post(`${BACKEND_URL}/vision/abnormal`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        this.abnormalityResult = abnormalityRes.data;

      } catch (error) {
        console.error('Error during image analysis:', error);
        alert('An error occurred while analyzing the image.');
      }
    }
  }
};
</script>

<style scoped>
.platform-size {
  width: 100%;
  padding: 10px !important;
}

.round-card {
  padding: 5px;
  border-radius: 15px;
}

.green-btn {
  background-color: var(--primary-color);
  color: white;
  padding: 8px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
</style>
