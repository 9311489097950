<template>
  <div v-if="show" class="image-overlay">
    <div class="overlay-content">
      <button class="close-btn" @click="closeViewer">
        <i class="fas fa-times"></i>
      </button>
      <img 
        :src="imageUrl" 
        :style="{ 
          filter: `brightness(${brightness}%) contrast(${contrast}%)`,
          width: width ? `${width}px` : 'auto',
          height: height ? `${height}px` : 'auto'
        }"
        alt="Full size image" 
      />
      <div class="image-controls">
        <div class="control-group">
          <span class="control-label">Brightness</span>
          <input type="range" v-model="brightness" min="0" max="200" step="10">
          <span class="control-value">{{brightness}}%</span>
        </div>
        <div class="control-group">
          <span class="control-label">Contrast</span>
          <input type="range" v-model="contrast" min="0" max="200" step="10">
          <span class="control-value">{{contrast}}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'ImageViewer',
props: {
  show: {
    type: Boolean,
    default: false
  },
  imageUrl: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    default: null
  },
  height: {
    type: Number,
    default: null
  }
},
data() {
  return {
    brightness: 100,
    contrast: 100
  }
},
mounted() {
  window.addEventListener('keydown', this.handleKeyDown)
},
beforeDestroy() {
  window.removeEventListener('keydown', this.handleKeyDown)
},
methods: {
  closeViewer() {
    this.$emit('update:show', false)
  },
  handleKeyDown(event) {
    if (event.key === 'Escape' && this.show) {
      this.closeViewer()
    }
  }
}
}
</script>

  
  <style scoped>
  .image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .overlay-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90vw;
    max-height: 90vh;
  }
  
  .overlay-content img {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
  }
  
  .close-btn {
    position: absolute;
    top: -40px;
    right: 0;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 8px;
  }
  
  .close-btn:hover {
    color: #ff4444;
  }
  
  .image-controls {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 2rem;
    background: rgba(0, 0, 0, 0.7);
    padding: 1rem 2rem;
    border-radius: 8px;
  }
  
  .control-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  .control-label {
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .control-value {
    color: white;
    font-size: 0.8rem;
  }
  
  input[type="range"] {
    width: 150px;
    cursor: pointer;
  }
  </style>
  