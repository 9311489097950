import { createRouter, createWebHistory } from 'vue-router';
import Analysis from '../pages/Analysis.vue';
import Login from '../pages/Login.vue';
import Profile from '../pages/Profile.vue';
import Patients from "../pages/Patients/Patients.vue";
import AddPatients from "../pages/Patients/AddPatients.vue";
import PatientsEdit from "../pages/Patients/EditPatients.vue";
import RetinaUpload from "../pages/Retina/RetinaUpload.vue";
import RetinaUploadPatient from "../pages/Retina/RetinaUploadPatient.vue";
import CasePatient from "../pages/Session/Case.vue";
import CaseCompare from "../pages/Session/CaseCompare.vue";
import CaseImage from "../pages/Session/CaseImage.vue";
import Dashboard from "../pages/Dashboard/Dashboard.vue"
import Referral from "../pages/Referral/Referral.vue"
import ReferralPatient from "../pages/Referral/ReferralPatient.vue"
import ReferralHistory from "../pages/Referral/ReferralHistory.vue"
import CPGChat from "../pages/CPG/CPGChat.vue"
import Error from "../pages/Error.vue"
import { useAuthStore } from '../stores/authStore';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/test',
    name: 'analysis',
    component: Analysis,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/patients',
    name: 'Patients',
    component: Patients,
    meta: { requiresAuth: true }
  },
  {
    path: '/patient/edit/:uuid',
    name: 'PatientsEdit',
    component: PatientsEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/patients/register',
    name: 'AddPatients',
    component: AddPatients,
    meta: { requiresAuth: true }
  },
  {
    path: '/retina/upload',
    name: 'RetinaUpload',
    component: RetinaUpload,
    meta: { requiresAuth: true }
  },
  {
    path: '/retina/upload/:patientUuid',
    name: 'RetinaUploadPatient',
    component: RetinaUploadPatient,
    meta: { requiresAuth: true }
  },
  {
    path: '/case/patient/:uuid',
    name: 'CasePatient',
    component: CasePatient,
    meta: { requiresAuth: true }
  },
  {
    path: '/case/compare/:uuid',
    name: 'CaseCompare',
    component: CaseCompare,
    meta: { requiresAuth: true }
  },
  {
    path: '/case/image/:patientUuid/:uuid',
    name: 'CaseImage',
    component: CaseImage,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/referral',
    name: 'Referral',
    component: Referral,
    meta: { requiresAuth: true }
  },
  {
    path: '/referral/history/:referralUuid',
    name: 'ReferralHistory',
    component: ReferralHistory,
    meta: { requiresAuth: true }
  },
  {
    path: '/referral/form/:patientUuid/:sessionUuid',
    name: 'ReferralPatient',
    component: ReferralPatient,
    meta: { requiresAuth: true }
  },
  {
    path: '/cpg-chat',
    name: 'cpg-chat',
    component: CPGChat,
    meta: { requiresAuth: true }
  },


  {
    path: '/:catchAll(.*)*',
    name: 'error',
    component: Error, // Catch-all route for 404 or unauthorized access
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  authStore.loadTokensFromStorage();

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = authStore.accessToken;

  if (requiresAuth && !isAuthenticated) {
    next('/login');
    return;
  }

  if (to.name === 'login' && isAuthenticated) {
    next({ name: 'analysis' });
  } else {
    next();
  }
});

export default router;
