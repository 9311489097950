<template>
    <div class="table-container">
      <!-- Elegant List View -->
      <div class="list-wrapper">
        <transition-group 
          tag="ul" 
          name="fadechange" 
          class="list-group border-0" 
          v-if="input_object.length > 0"
        >
          <li 
            v-for="(data, key, index) in paginate_list(input_object)[current_page]" 
            :key="key" 
            class="list-group-item border-0 text-start hover-effect"
          >
            <slot :data="data"></slot>
          </li>
        </transition-group>
        
        <!-- Empty State -->
        <div v-else class="empty-state">
          <div class="text-center my-4">
            <i class="fas fa-inbox fa-3x text-muted mb-3"></i>
            <h5 class="text-muted">No Data Available</h5>
          </div>
        </div>
      </div>
  
      <!-- Enhanced Pagination -->
      <nav aria-label="Page navigation" class="pagination-wrapper">
        <div class="d-flex justify-content-between align-items-center mt-4">
          <!-- Page Size Selector -->
          <div class="page-size-selector">
            <select 
              class="form-select form-select-sm border-0" 
              v-model="page_limit"
            >
              <option disabled>Rows</option>
              <option v-for="size in [5,10,15,20,25,30]" :key="size" :value="size">
                {{ size }} per page
              </option>
            </select>
          </div>
  
          <!-- Pagination Controls -->
          <ul class="pagination pagination-md mb-0">
            <li class="page-item border-0" :class="{ disabled: current_page === 0 }">
              <a class="page-link border-0" @click="current_page = Math.max(0, current_page - 1)">
                <i class="fas fa-chevron-left"></i>
              </a>
            </li>
  
            <li 
              v-for="(data, key) in paginate_list(input_object)" 
              :key="key"
              class="page-item border-0"
              :class="{ active: current_page == key }"
            >
              <a 
                class="page-link border-0" 
                @click="current_page = parseInt(key)"
              >
                {{ parseInt(key) + 1 }}
              </a>
            </li>
  
            <li class="page-item border-0" 
                :class="{ disabled: current_page >= (count_obj(paginate_list(input_object))-1) }"
            >
              <a class="page-link border-0" @click="current_page = Math.min(count_obj(paginate_list(input_object))-1, current_page + 1)">
                <i class="fas fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </template>
  
  <script>
  
  
  export default{
  props: {
      // Define 'message' as a prop to receive data from parent
      input_object: {
          required: true,
      },
  },
  
  created(){
      console.warn("Created DOM!");
  },
  data() {
      return {
          current_page : 0,
          page_limit : 10,
      }
  },
  computed: {
  
  },
  methods: {
  
  paginate_list(data){
    let count = 1;
    let index = 0;
    let limit = this.page_limit; //limit data per pages
    let new_data = {};
    let temp_arr = {};
  
    for (const [key, value] of Object.entries(data)) {
      if(count<limit){
        temp_arr[key] = value;
        new_data[index] = temp_arr
        count++;
      }
      else{
        temp_arr[key] = value;
        new_data[index] = temp_arr,
          
        temp_arr = {}; //reset temp_arr
        index++;
        count = 1;
      }
    }
  
    //returns example [index of pages] -> [data under limit = 10] 
    console.log(new_data);
    return new_data;
  
  },
  
  count_obj(data){
    let count = 0;
    for (const [key, value] of Object.entries(data)) {
      count++
    }
    return count;
  },
  
  }
  }
  </script>
  
  <style scoped>
  .table-container {
    background: #fff;
    border-radius: 8px;
    padding: 1rem;
  }
  
  .list-wrapper {
    min-height: 200px;
  }
  
  .list-group-item {
    border-radius: 8px !important;
  }
  
  .hover-effect:hover {
    background-color: #f1f3f5;
    transform: translateX(4px);
  }
  
  .pagination-wrapper {
    padding-top: 1rem;
  }
  
  .page-link {
    color: var(--primary-color);
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    background: transparent;
  }
  
  .page-item.active .page-link {
    background-color: var(--primary-color);
    color: white;
  }
  
  .page-item.disabled .page-link {
    background: transparent;
  }
  
  .form-select {
    background-color: #f8f9fa;
    cursor: pointer;
  }
  
  .form-select:focus {
    box-shadow: none;
    background-color: #f1f3f5;
  }
  
  /* Animation classes */
  .fadechange-enter-active,
  .fadechange-leave-active {
    transition: all 0.3s ease;
  }
  
  .fadechange-enter-from,
  .fadechange-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
  </style>