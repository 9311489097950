import axios from 'axios';
import { useAuthStore } from '../stores/authStore';

const BACKEND_URL = "https://drmatabackend-584749671098.asia-southeast1.run.app"

export const getUser = async () => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_user/details`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getUser(); // Retry after refreshing
        }
        throw error;
    }
}

export const getAllUser = async () => {
    const authStore = useAuthStore();

    try {
        const response = await axios.get(`${BACKEND_URL}/nora_superadmin/users`, {
        headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
        },
        });
    
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return getAllUser(); // Retry after refreshing
        }
        throw error;
    }
}  
export const registerUser = async (userData) => {
    const authStore = useAuthStore();

    console.log(userData);

    try {
      const response = await axios.post(`${BACKEND_URL}/nora_superadmin/register`, userData, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return registerUser(userData); // Retry after refreshing
        }
        throw error;
    }
};

export const updateLocationUser = async (data) => {
    const authStore = useAuthStore();

    try {
      const response = await axios.post(`${BACKEND_URL}/nora_superadmin/update_user_locations`, data, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
  
      return response.data; // Return response data upon successful registration
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Token might be expired, attempt to refresh
            await authStore.refreshAccessToken();
            return updateLocationUser(data); // Retry after refreshing
        }
        throw error;
    }
  };


